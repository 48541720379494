import React from 'react'
import { Helmet } from 'react-helmet'
import { MDXProvider } from '@mdx-js/react'
import SiteMetadata from '../components/SiteMetadata'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import FloatingCTA from '../components/FloatingCTA'
import shortcodes from '../components/shortcodes'
import '../styles/_all.sass'

/**
 * Construct the global layout
 *
 * @param {string} className
 */
const TemplateWrapper = props => {
  const { globalSchema } = SiteMetadata()

  return (
    <div>
      <Helmet>
        <html lang="en-gb" />
        <title>{props.seoTitle || props.title}</title>
        <meta name="description" content={props.seoDescription} />
        <script type="application/ld+json">{globalSchema}</script>
        <script type="application/ld+json">{props.seoSchema}</script>
      </Helmet>
      <Navbar /> 
      <div id="main" className={props.className}>
        <MDXProvider components={shortcodes}>{props.children}</MDXProvider>
      </div>
      <Footer ctaText={props.ctaText || ''} />
      <FloatingCTA />
    </div>
  )
}

export default TemplateWrapper
