import React from "react"
import SwatchesStyles from '../../styles/Swatches.module.sass'

/**
 * Display a section of colour swatches
 */
const Swatches = () => {
	// Define class names to separate logic from JSX structure
	const swatchesContainerClassList = SwatchesStyles.container || ''

	return (
		<ul className={`columns is-mobile is-centered is-multiline ${swatchesContainerClassList}`}>
			<li className='column is-one-quarter-mobile is-one-quarter-tablet is-one-fifth-desktop'>
				<div>
					<span style={{ backgroundColor: '#fff' }}></span>
					<em>White</em>
				</div>
			</li>
			<li className='column is-one-quarter-mobile is-one-quarter-tablet is-one-fifth-desktop'>
				<div>
					<span style={{ backgroundColor: '#CFCAC6' }}></span>
					<em>Natural Grey</em>
				</div>
			</li>
			<li className='column is-one-quarter-mobile is-one-quarter-tablet is-one-fifth-desktop'>
				<div>
					<span style={{ backgroundColor: '#484844' }}></span>
					<em>Gris Gu</em>
				</div>
			</li>
			<li className='column is-one-quarter-mobile is-one-quarter-tablet is-one-fifth-desktop'>
				<div>
					<span style={{ backgroundColor: '#9AACC2' }}></span>
					<em>Azul Piedra</em>
				</div>
			</li>
			<li className='column is-one-quarter-mobile is-one-quarter-tablet is-one-fifth-desktop'>
				<div>
					<span style={{ backgroundColor: '#3D567E' }}></span>
					<em>Azul Eo</em>
				</div>
			</li>
			<li className='column is-one-quarter-mobile is-one-quarter-tablet is-one-fifth-desktop'>
				<div>
					<span>
						<img src='/media/swatches/swatch-creta-gris.png' alt='Toilet Cubicle Colour Creta Gris' />
						<i>Textured</i>
					</span>
					<em>Creta Gris</em>
				</div>
			</li>
			<li className='column is-one-quarter-mobile is-one-quarter-tablet is-one-fifth-desktop'>
				<div>
					<span>
						<img src='/media/swatches/swatch-lava-stone.png' alt='Toilet Cubicle Colour Lava Stone' />
						<i className='has-text-white'>Textured</i>
					</span>
					<em>Lava Stone</em>
				</div>
			</li>
			<li className='column is-one-quarter-mobile is-one-quarter-tablet is-one-fifth-desktop'>
				<div>
					<span>
						<img src='/media/swatches/swatch-hickory-frida.png' alt='Toilet Cubicle Colour Hickory Frida' />
						<i className='has-text-white'>Textured</i>
					</span>
					<em>Hickory Frida</em>
				</div>
			</li>
			<li className='column is-one-quarter-mobile is-one-quarter-tablet is-one-fifth-desktop'>
				<div>
					<span>
						<img src='/media/swatches/swatch-cambrian-oak.png' alt='Toilet Cubicle Colour Cambrian Oak' />
						<i className='has-text-white'>Textured</i>
					</span>
					<em>Cambrian Oak</em>
				</div>
			</li>
			<li className='column is-one-quarter-mobile is-one-quarter-tablet is-one-fifth-desktop'>
				<div>
					<span>
						<img src='/media/swatches/swatch-nogal-valentina.png' alt='Toilet Cubicle Colour Nogal Valentina' />
						<i className='has-text-white'>Textured</i>
					</span>
					<em>Nogal Valentina</em>
				</div>
			</li>
		</ul>
	)
}

export default Swatches