import React, { useState } from 'react'
import ContactFormStyles from '../../styles/ContactForm.module.sass'

/**
 * Display a functional contact form
 *
 * @param {string} className
 * @param {string} buttonClass
 * @param {boolean} formOnly Toggles top info section | Default: false
 */
const ContactForm = props => {

  const initialEmailSubjectString = 'Toilet Cubicles Enquiry'

  const [emailSubject, setEmailSubject] = useState(initialEmailSubjectString)

  const handleSetEmailSubject = (customerName) => {
    if (customerName && customerName.trim().length) {
      setEmailSubject(initialEmailSubjectString + ' from ' + customerName)
    }
    else {
      setEmailSubject(initialEmailSubjectString)
    }
  }

  return (
    <div
      className={`form-container ${ContactFormStyles.form} ${props.className ||
        ''}`}
    >
      <div>
        {(props.formOnly !== true || props.formOnly !== 'true') && (
          <>
            <span className={`title ${ContactFormStyles.title}`}>
              Get a Quote
            </span>
            <p>
              Fill out the form below and we will get back to you as soon as possible.
            </p>
          </>
        )}

        <form
          name="Toilet Cubicles Enquiry"
          method="post"
          action="/thanks/"
          data-netlify="true"
          data-netlify-honeypot="first-name"
          netlify="true"
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="Toilet Cubicles Enquiry" />
          <input type="hidden" name="subject" value={emailSubject} />
          <div hidden>
            <label>
              Trap for non-humans: <input name="first-name" />
            </label>
          </div>
          {
            props.numberOfCubicles &&
            <div className="field">
              <label htmlFor="numberOfCubicles" hidden>
                Number of cubicles
              </label>
              <div className='control'>
                <div className='select'>
                  <select 
                    defaultValue={props.numberOfCubicles}
                    name='numberOfCubicles'
                    id='numberOfCubicles'
                  >
                    <option disabled>Number of Cubicles</option>
                    <option value={1}>Single Toilet Cubicle</option>
                    <option value={2}>Row of 2 Cubicles</option>
                    <option value={3}>Row of 3 Cubicles</option>
                    <option value={4}>Row of 4 Cubicles</option>
                    <option value={5}>Row of 5 Cubicles</option>
                    <option value={6}>Row of 6 Cubicles</option>
                  </select>
                </div>
              </div>
            </div>
          }
          {
            props.supplySelector &&
            <div className="field">
              <label htmlFor="supplySelector" hidden>
                Choose Services
              </label>
              <div className='control'>
                <div className='select'>
                  <select
                    defaultValue='supply-and-installation'
                    name='supplySelector'
                    id='supplySelector'
                  >
                    <option disabled>Choose Services</option>
                    <option value='supply-and-installation'>Supply and Installation</option>
                    <option value='supply-only'>Supply Only</option>
                  </select>
                </div>
              </div>
            </div>
          }
          {
            props.wallSelector &&
            <div className="field">
              <label htmlFor="wallSelector" hidden>
                Cubicle Layout
              </label>
              <div className='control'>
                <div className='select'>
                  <select
                    defaultValue='wall-on-one-side'
                    name='wallSelector'
                    id='wallSelector'
                  >
                    <option disabled>Choose Layout</option>
                    <option value='between-walls'>Between walls</option>
                    <option value='wall-on-one-side'>Wall on one side</option>
                    <option value='away-from-walls'>Away from walls (Island)</option>
                  </select>
                </div>
              </div>
            </div>
          }
          <div className="field">
            <div className="control">
              <label htmlFor="name" hidden>
                Name
              </label>
              <input
                className="input"
                type={'text'}
                name={'name'}
                id={'name'}
                required={true}
                placeholder={'Your Name'}
                onChange={e => handleSetEmailSubject(e.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <label htmlFor="email" hidden>
                Email
              </label>
              <input
                className="input"
                type={'email'}
                name={'email'}
                id={'email'}
                required={true}
                placeholder={'Your E-Mail'}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <label htmlFor="phone" hidden>
                Phone
              </label>
              <input
                className="input"
                type={'tel'}
                name={'phone'}
                id={'phone'}
                required={true}
                placeholder={'Phone Number'}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <label htmlFor="message" hidden>
                Message
              </label>
              <textarea
                className="textarea"
                name={'message'}
                id={'message'}
                required={true}
                placeholder={'Your enquiry'}
              />
            </div>
          </div>
          <div className="field">
            <button
              className={`button is-large is-fullwidth ${ContactFormStyles.submit} ${props.buttonClass ? props.buttonClass : 'is-link'}`}
              type="submit"
            >
              Send Enquiry
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ContactForm
