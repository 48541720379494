import React from 'react'
import GalleryStyles from '../../styles/Gallery.module.sass'

/**
 * Display a section with Gallery
 */
const Gallery = () => {
  return (
    <div className={`force-full-width ${GalleryStyles.gallery || ''}`}>
      <div
        className={`container`}
      >
        <div className={`columns is-multiline ${GalleryStyles.columns || ''}`}>
          <div className={`column is-half is-one-third-desktop`}>
            <img src="/media/toilet-cubicles-ireland.jpg" alt="toilet cubicles ireland" />
          </div>

          <div className={`column is-half is-one-third-desktop`}>
            <img src="/media/toilet-cubicle.jpg" alt="toilet cubicle" />
          </div>

          <div className={`column is-half is-one-third-desktop`}>
            <img src="/media/restroom-cubicles.jpg" alt="restroom cubicles" />
          </div>

          <div className={`column is-half is-two-thirds-desktop is-hidden-touch`}>
            <img src="/media/toilet-cubicles-dublin.jpg" alt="toilet cubicles dublin" />
          </div>

          <div className={`column is-half is-one-third-desktop is-hidden-mobile`}>
            <img src="/media/toilet-cubicle-fitout.jpg" alt="toilet cubicle fitout" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Gallery