// Match all .js files in the current folder
const shortcodesContext = require.context('.', true, /\.jsx?$/)

const shortcodes = shortcodesContext.keys().reduce((acc, path) => {
	// Get the component name from the file name
	const name = path.replace('./', '').replace(/\.\w+$/, '')

	// Assign the default export
	acc[name] = shortcodesContext(path).default

	return acc
}, {})

export default shortcodes