import React from 'react'
import SiteMetadata from './SiteMetadata'
import CTA from './shortcodes/CTA'
import LeinsterGroupLogo from '../../static/media/leinster-group-logo.svg'
import FooterStyles from '../styles/Footer.module.sass'

/**
 * Display the global footer
 *
 * @param {string} ctaText
 */
const Footer = (props) => {
  const { title } = SiteMetadata()

  return (
    <>
      <div className={FooterStyles.footerCta || ''}>
        <i className={`title is-2`}>{props.ctaText || 'Request a quote for a bathroom fitout:'}</i>
        <CTA class="is-white" />
      </div>
      <footer className={`footer ${FooterStyles.footer}`}>
        <div className="container has-text-centered">
          <a
            className={FooterStyles.owner || ''}
            href="https://leinstergroup.ie/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={LeinsterGroupLogo}
              alt="Leinster Group"
              style={{
                width: '177px',
                height: '58px',
              }}
            />
          </a>
          <p>
            Copyright{' '}
            <span className="current-year">{new Date().getFullYear()}</span>{' '}
            {title}. All rights reserved
            <span className={FooterStyles.miniMap || ''}>Made in Dublin</span>
          </p>
        </div>
      </footer>
    </>
  )
}

export default Footer
