import React, { useState } from 'react'
import { Link } from 'gatsby'
import SiteMetadata from './SiteMetadata'
import logo from '../../static/media/logo.svg'
import MainMenu from '../data/menus.json'
import Phone from './shortcodes/Phone'
import NavbarStyles from '../styles/Navbar.module.sass'

/**
 * Display the global header
 */
const Navbar = () => {
  const { title, phone } = SiteMetadata()

  // Grab menu from JSON using this slug
  const menuUsedSlug = 'main'

  const [navbarState, setNavbarState] = useState(false)

  const toggleState = (newState = !navbarState) => {
    setNavbarState(newState)
  }

  return (
    <nav
      className={`navbar is-transparent ${NavbarStyles.navbar || ''}`}
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          {/* Hamburger menu */}
          <span
            onClick={() => toggleState()}
            onKeyDown={() => toggleState()}
            className={`navbar-burger burger ${navbarState ? 'is-active' : ''}`}
            role="button"
            tabIndex="0"
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </span>

          <Phone class={`with-icon ${NavbarStyles.phone}`} />

          <Link
            to="/"
            className={`navbar-item ${NavbarStyles.logoHolder}`}
            title="Logo"
          >
            <img src={logo} alt={title} className={`${NavbarStyles.logo}`} />
          </Link>

          {/* Mobile Call Button */}
          <a
            href={`tel:${phone}`}
            className={`mobile-phone-button is-hidden-tablet ${NavbarStyles.mobilePhoneButton || ''}`}
          >
            Call
          </a>

          <Link
            to="/contact/"
            className={`button is-link ${NavbarStyles.buttonGetQuote}`}
          >
            Get a Quote
          </Link>
        </div>
      </div>
      <div className="container">
        <div
          id="navMenu"
          className={`navbar-menu ${navbarState ? 'is-active' : ''} ${NavbarStyles.menu}`}
        >
          <div
            className={`navbar-start has-text-centered ${NavbarStyles.navbarStart}`}
          >
            {MainMenu.menus
              .find(menu => menu.title === menuUsedSlug)
              .links.map(({ text, link, links }) =>
                links ? (
                  <div
                    key={text + link}
                    className="navbar-item has-dropdown is-hoverable"
                  >
                    <Link
                      key={text}
                      to={link}
                      className="navbar-item"
                      activeClassName="is-active"
                    >
                      {text}
                    </Link>
                    <div
                      className={`navbar-dropdown ${NavbarStyles.navbarDropdown ||
                        ''}`}
                    >
                      {links.map(({ text, link, mobile }) => (
                        <Link
                          key={text}
                          to={link}
                          className="navbar-item xyz1200"
                          activeClassName="is-active"
                          data-mobile={mobile || "show"}
                        >
                          {text}
                        </Link>
                      ))}
                    </div>
                  </div>
                ) : (
                  <Link
                    key={text}
                    to={link}
                    className="navbar-item"
                    activeClassName="is-active"
                  >
                    {text}
                  </Link>
                ))}
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
