import React from 'react'
import ProductListStyles from '../../styles/ProductList.module.sass'
import Img from 'gatsby-image'
import { graphql, useStaticQuery, Link } from 'gatsby'

/**
 * Display a section with multiple products
 */
const ProductList = () => {
	const data = useStaticQuery(products)

	// Define class names to separate logic from JSX structure
	const containerClassNames = ProductListStyles.container || ''
	const productListClassNames = ProductListStyles.productList || ''
	const productClassNames = ProductListStyles.product || ''
	const coverLinkClassNames = ProductListStyles.coverLink || ''
	const headerClassNames = ProductListStyles.header || ''
	const imageClassNames = ProductListStyles.image || ''
	const priceClassNames = ProductListStyles.price || ''
	const bodyClassNames = ProductListStyles.body || ''
	const productTitleClassNames = ProductListStyles.productTitle || ''
	const shortDescriptionClassNames = ProductListStyles.shortDescription || ''
	const shortContentsClassNames = ProductListStyles.shortContents || ''
	const buttonClassNames = ProductListStyles.button || ''

	return (
		<div
			className={`force-full-width ${containerClassNames}`}
		>
			<div className='container'>
				<div className={`columns is-multiline ${productListClassNames}`}>
					{data.allMdx.edges.map(({ node }) => {
						return (
							<div
								key={node.id}
								className={`column is-one-third-desktop is-half-tablet ${productClassNames}`}
							>
								<div>
									<Link
										className={coverLinkClassNames} 
										to={`/${node.fields.slug}`}
									/>
									<div className={headerClassNames}>
										<Img
											className={imageClassNames}
											fluid={node.fields.image.childImageSharp.fluid}
										/>
										<span className={priceClassNames}>
											<small>from</small>
											<em>&euro;{node.frontmatter.priceSupply}</em>
										</span>
									</div>
									<div className={bodyClassNames}>
										<strong className={`title is-4 ${productTitleClassNames}`}>
											{node.frontmatter.title}
										</strong>
										<p className={shortDescriptionClassNames}>
											{node.frontmatter.shortDescription}
										</p>
										<ul className={shortContentsClassNames}>
											{node.frontmatter.shortContents.map((item, index) => {
												return (
													<li key={index}>{item}</li>
												)
											})}
										</ul>
										<span className={`button is-primary ${buttonClassNames}`}>Order Now</span>
									</div>
								</div>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default ProductList

export const products = graphql`
{
  allMdx(
    filter: { frontmatter: { templateKey: { eq: "product" } } }
    sort: { fields: frontmatter___productId, order: ASC }
  ) {
    edges {
      node {
				id
        frontmatter {
          productId
					title
					priceSupply
					shortDescription
					shortContents
        }
        fields {
          slug
					image {
						childImageSharp {
							fluid(maxWidth: 400) {
								...GatsbyImageSharpFluid
							}
						}
					}
        }
      }
    }
  }
}`